<template>
  <div class="generic-layout">
    <Header />
    <div class="price-page">
      <section class="py-4 py-md-5">
        <div class="container text-center">
          <div class="heading-head">
            <!-- <h1>Let us be your training partner!</h1>
            <p>Talk to a live US-based sales rep and see how we deliver real value as a partner in your success.</p> -->
            <p class="m-0">
              <strong class="fw-bold">To create content or license our courses to use on another platform, click here to
                <a href="javascript:void()" style="cursor: pointer;" @click="dialogVisible = true">Speak with a sales representative NOW.</a></strong>
            </p>
          </div>
        </div>
      </section>
      <section class="pb-4 pb-md-5">
  <div class="container">
    <div class="price_tab">
      <div class="outer-links d-flex justify-content-center mb-4 mb-md-5">
        <ul class="list-unstyled d-flex p-2 border rounded-5 mb-0" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active fw-semibold" id="tab-monthly-btn" data-bs-toggle="tab" data-bs-target="#tab-monthly">Monthly</a>
          </li>
          <li class="nav-item">
            <a class="nav-link position-relative fw-semibold" id="tab-annualy-btn" data-bs-toggle="tab" data-bs-target="#tab-annualy">
              <span class="save-annualy">Save 10%</span> Annually
            </a>
          </li>
        </ul>
      </div>
      <div class="tab-content pt-4" id="myTabContent">
        <!-- Monthly Tab -->
        <div class="tab-pane fade show active" id="tab-monthly">
          <div class="row">
            <!-- Hot Plan -->
            <div class="col-lg-6 col-xl-4 mb-4 mb-xl-0">
              <div class="price-block border p-4 rounded-4 bg-white">
                <div v-if="!activeTrial || activeTrial !== 'hot-monthly'" class="price-block-inner hot-plane">
                  <div class="d-flex align-items-center mb-3">
                    <img src="img/price/hot-plan.svg" alt="" />
                    <h4 class="mb-0 px-3 fw-bold">Hot Plan</h4>
                    <el-tooltip popper-class="plan-tool-tip" placement="right" effect="light">
                      <div slot="content">
                        <div class="trial-tool-tip p-3">
                          <p class="fw-bold">The 14-day free trial includes the five courses below.  You can add as many users as you’d like.</p>
                          <p>After 14 days (or by clicking Get Started on your Dashboard), your account will become active and all courses you signed up for will be assigned to your account. </p>
                          <p>You can click to end the free trial or send us an email to cancel with no obligation.</p>
                          <h6>Available Courses</h6>
                          <ul class="list-unstyled m-0 p-0">
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Active Shooter</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Diversity in the Workplace</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Slips, Trips, and Falls</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Discipline Without Punishment</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Telephone Etiquette</h6></div></li>
                          </ul>
                        </div>
                      </div>
                      <el-button><span class="ps-2"><img src="img/price/info.svg" alt="" /></span></el-button>
                    </el-tooltip>
                  </div>
                  <p class="mb-1">Includes the required Food and Alcohol compliance courses only.</p>
                  <strong class="price fs-1 fw-bold mb-1 lh-sm">$1.49 - 1.99</strong>
                  <span class="price-text fs-6 d-block mb-4">Per user, per month.</span>
                  <div class="price-action-area text-center">
                    <router-link :to="{ path: '/company_register', query: { plan: 'hot', type: 'monthly' } }">
                      <button class="btn btn-primary d-block w-100 py-2 mb-3 rounded-3 fw-bold">Get Started</button>
                    </router-link>
                    <button class="btn btn-outline-primary d-block w-100 py-2 mb-3 rounded-3 fw-bold" @click="showTrial('hot-monthly')">Start Free 14 Day Trial</button>
                    <a  style="cursor: pointer;" @click="dialogVisible = true" class="text-underline fw-bold text-dark">Schedule a Demo</a>
                  </div>
                </div>
                <div v-else class="price-block-inner hot-plane replace-box position-relative">
                  <span @click="hideTrial()" class="close-btn d-flex justify-content-end position-absolute">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 14L8 8L14 14M14 2L7.99886 8L2 2" stroke="#A39D9D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <p class="fw-bold mb-3 height-auto">The 14-day free trial includes the five courses below. You can add as many users as you’d like.</p>
                  <p class="mb-3">After 14 days (or by clicking Get Started on your Dashboard), your account will become active and all courses you signed up for will be assigned to your account.</p>
                  <p class="mb-3">You can click to end the free trial or send us an email to cancel with no obligation.</p>
                  <h6 class="fw-bold">Available Courses</h6>
                  <ul class="list-unstyled m-0 p-0">
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Active Shooter</h6></div></li>
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Diversity in the Workplace</h6></div></li>
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Slips, Trips, and Falls</h6></div></li>
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Discipline Without Punishment</h6></div></li>
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Telephone Etiquette</h6></div></li>
                  </ul>
                  <div class="price-action-area text-center mt-3">
                    <router-link :to="{ path: '/company_register', query: { plan: 'hot', type: 'monthly', freeTrial: true } }">
                      <button class="btn btn-outline-primary d-block w-100 py-2 rounded-3 fw-bold">Start Free Trial</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <!-- Sizzling Plan -->
            <div class="col-lg-6 col-xl-4 mb-4 mb-xl-0">
              <div class="popular-outer rounded-4">
                <span class="popular-text text-uppercase text-center text-white fw-medium d-block">Popular</span>
                <div class="price-block p-4 custom-border rounded-4 bg-white">
                  <div v-if="!activeTrial || activeTrial !== 'sizzling-monthly'" class="price-block-inner hot-plane">
                    <div class="d-flex align-items-center mb-3">
                      <img src="img/price/sizzle-plan.svg" alt="" />
                      <h4 class="mb-0 px-3 fw-bold">Sizzling Plan</h4>
                      <el-tooltip popper-class="plan-tool-tip" placement="right" effect="light">
                      <div slot="content">
                        <div class="trial-tool-tip p-3">
                          <p class="fw-bold">The 14-day free trial includes the five courses below.  You can add as many users as you’d like.</p>
                          <p>After 14 days (or by clicking Get Started on your Dashboard), your account will become active and all courses you signed up for will be assigned to your account. </p>
                          <p>You can click to end the free trial or send us an email to cancel with no obligation.</p>
                          <h6>Available Courses</h6>
                          <ul class="list-unstyled m-0 p-0">
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Active Shooter</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Diversity in the Workplace</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Slips, Trips, and Falls</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Discipline Without Punishment</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Telephone Etiquette</h6></div></li>
                          </ul>
                        </div>
                      </div>
                      <el-button><span class="ps-2"><img src="img/price/info.svg" alt="" /></span></el-button>
                    </el-tooltip>
                    </div>
                    <p class="mb-1">Includes the required Food and Alcohol compliance courses, Sexual Harassment, 26 course safety program.</p>
                    <strong class="price fs-1 fw-bold mb-1 lh-sm">$1.99 - 2.49</strong>
                    <span class="price-text fs-6 d-block mb-4">Per user, per month.</span>
                    <div class="price-action-area text-center">
                      <router-link :to="{ path: '/company_register', query: { plan: 'sizzling', type: 'monthly' } }">
                        <button class="btn btn-primary d-block w-100 py-2 mb-3 rounded-3 fw-bold">Get Started</button>
                      </router-link>
                      <button class="btn btn-outline-primary d-block w-100 py-2 mb-3 rounded-3 fw-bold" @click="showTrial('sizzling-monthly')">Start Free 14 Day Trial</button>
                      <a style="cursor: pointer;" @click="dialogVisible = true" class="text-underline fw-bold text-dark">Schedule a Demo</a>
                    </div>
                  </div>
                  <div v-else class="price-block-inner hot-plane replace-box position-relative">
                    <span @click="hideTrial()" class="close-btn d-flex justify-content-end position-absolute">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 14L8 8L14 14M14 2L7.99886 8L2 2" stroke="#A39D9D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </span>
                    <p class="fw-bold mb-3 height-auto">The 14-day free trial includes the five courses below. You can add as many users as you’d like.</p>
                    <p class="mb-3">After 14 days (or by clicking Get Started on your Dashboard), your account will become active and all courses you signed up for will be assigned to your account.</p>
                    <p class="mb-3">You can click to end the free trial or send us an email to cancel with no obligation.</p>
                    <h6 class="fw-bold">Available Courses</h6>
                    <ul class="list-unstyled m-0 p-0">
                      <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Active Shooter</h6></div></li>
                      <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Diversity in the Workplace</h6></div></li>
                      <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Slips, Trips, and Falls</h6></div></li>
                      <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Discipline Without Punishment</h6></div></li>
                      <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Telephone Etiquette</h6></div></li>
                    </ul>
                    <div class="price-action-area text-center mt-3">
                      <router-link :to="{ path: '/company_register', query: { plan: 'sizzling', type: 'monthly', freeTrial: true } }">
                        <button class="btn btn-outline-primary d-block w-100 py-2 rounded-3 fw-bold">Start Free Trial</button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Scorching Plan -->
            <div class="col-lg-6 col-xl-4">
              <div class="price-block border p-4 rounded-4 bg-white">
                <div v-if="!activeTrial || activeTrial !== 'scorching-monthly'" class="price-block-inner hot-plane">
                  <div class="d-flex align-items-center mb-3">
                    <img src="img/price/scorching.svg" alt="" />
                    <h4 class="mb-0 px-3 fw-bold">Scorching Plan</h4>
                    <el-tooltip popper-class="plan-tool-tip" placement="right" effect="light">
                      <div slot="content">
                        <div class="trial-tool-tip p-3">
                          <p class="fw-bold">The 14-day free trial includes the five courses below.  You can add as many users as you’d like.</p>
                          <p>After 14 days (or by clicking Get Started on your Dashboard), your account will become active and all courses you signed up for will be assigned to your account. </p>
                          <p>You can click to end the free trial or send us an email to cancel with no obligation.</p>
                          <h6>Available Courses</h6>
                          <ul class="list-unstyled m-0 p-0">
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Active Shooter</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Diversity in the Workplace</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Slips, Trips, and Falls</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Discipline Without Punishment</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Telephone Etiquette</h6></div></li>
                          </ul>
                        </div>
                      </div>
                      <el-button><span class="ps-2"><img src="img/price/info.svg" alt="" /></span></el-button>
                    </el-tooltip>
                  </div>
                  <p class="mb-1">Includes Food and Alcohol compliance courses, Sexual Harassment, and our entire library.</p>
                  <strong class="price fs-1 fw-bold mb-1 lh-sm">$2.49 - 2.99</strong>
                  <span class="price-text fs-6 d-block mb-4">Per user, per month.</span>
                  <div class="price-action-area text-center">
                    <router-link :to="{ path: '/company_register', query: { plan: 'scorching', type: 'monthly' } }">
                      <button class="btn btn-primary d-block w-100 py-2 mb-3 rounded-3 fw-bold">Get Started</button>
                    </router-link>
                    <button class="btn btn-outline-primary d-block w-100 py-2 mb-3 rounded-3 fw-bold" @click="showTrial('scorching-monthly')">Start Free 14 Day Trial</button>
                    <a style="cursor: pointer;" @click="dialogVisible = true" class="text-underline fw-bold text-dark">Schedule a Demo</a>
                  </div>
                </div>
                <div v-else class="price-block-inner hot-plane replace-box position-relative">
                  <span @click="hideTrial()" class="close-btn d-flex justify-content-end position-absolute">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 14L8 8L14 14M14 2L7.99886 8L2 2" stroke="#A39D9D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <p class="fw-bold mb-3 height-auto">The 14-day free trial includes the five courses below. You can add as many users as you’d like.</p>
                  <p class="mb-3">After 14 days (or by clicking Get Started on your Dashboard), your account will become active and all courses you signed up for will be assigned to your account.</p>
                  <p class="mb-3">You can click to end the free trial or send us an email to cancel with no obligation.</p>
                  <h6 class="fw-bold">Available Courses</h6>
                  <ul class="list-unstyled m-0 p-0">
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Active Shooter</h6></div></li>
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Diversity in the Workplace</h6></div></li>
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Slips, Trips, and Falls</h6></div></li>
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Discipline Without Punishment</h6></div></li>
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Telephone Etiquette</h6></div></li>
                  </ul>
                  <div class="price-action-area text-center mt-3">
                    <router-link :to="{ path: '/company_register', query: { plan: 'scorching', type: 'monthly', freeTrial: true } }">
                      <button class="btn btn-outline-primary d-block w-100 py-2 rounded-3 fw-bold">Start Free Trial</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Annually Tab -->
        <div class="tab-pane fade" id="tab-annualy" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
          <div class="row">
            <!-- Hot Plan -->
            <div class="col-lg-6 col-xl-4 mb-4 mb-xl-0">
              <div class="price-block border p-4 rounded-4 bg-white">
                <div v-if="!activeTrial || activeTrial !== 'hot-yearly'" class="price-block-inner hot-plane">
                  <div class="d-flex align-items-center mb-3">
                    <img src="img/price/hot-plan.svg" alt="" />
                    <h4 class="mb-0 px-3 fw-bold">Hot Plan</h4>
                    <el-tooltip popper-class="plan-tool-tip" placement="right" effect="light">
                      <div slot="content">
                        <div class="trial-tool-tip p-3">
                          <p class="fw-bold">The 14-day free trial includes the five courses below.  You can add as many users as you’d like.</p>
                          <p>After 14 days (or by clicking Get Started on your Dashboard), your account will become active and all courses you signed up for will be assigned to your account. </p>
                          <p>You can click to end the free trial or send us an email to cancel with no obligation.</p>
                          <h6>Available Courses</h6>
                          <ul class="list-unstyled m-0 p-0">
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Active Shooter</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Diversity in the Workplace</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Slips, Trips, and Falls</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Discipline Without Punishment</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Telephone Etiquette</h6></div></li>
                          </ul>
                        </div>
                      </div>
                      <el-button><span class="ps-2"><img src="img/price/info.svg" alt="" /></span></el-button>
                    </el-tooltip>
                  </div>
                  <p class="mb-1">Includes the required Food and Alcohol compliance courses only.</p>
                  <strong class="price fs-1 fw-bold mb-1 lh-sm">$16.09 - 21.49</strong>
                  <span class="price-text fs-6 d-block mb-4">Per user, per year.</span>
                  <div class="price-action-area text-center">
                    <router-link :to="{ path: '/company_register', query: { plan: 'hot', type: 'yearly' } }">
                      <button class="btn btn-primary d-block w-100 py-2 mb-3 rounded-3 fw-bold">Get Started</button>
                    </router-link>
                    <button class="btn btn-outline-primary d-block w-100 py-2 mb-3 rounded-3 fw-bold" @click="showTrial('hot-yearly')">Start Free 14 Day Trial</button>
                    <a style="cursor: pointer;" @click="dialogVisible = true" class="text-underline fw-bold text-dark">Schedule a Demo</a>
                  </div>
                </div>
                <div v-else class="price-block-inner hot-plane replace-box position-relative">
                  <span @click="hideTrial()" class="close-btn d-flex justify-content-end position-absolute">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 14L8 8L14 14M14 2L7.99886 8L2 2" stroke="#A39D9D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <p class="fw-bold mb-3 height-auto">The 14-day free trial includes the five courses below. You can add as many users as you’d like.</p>
                  <p class="mb-3">After 14 days (or by clicking Get Started on your Dashboard), your account will become active and all courses you signed up for will be assigned to your account.</p>
                  <p class="mb-3">You can click to end the free trial or send us an email to cancel with no obligation.</p>
                  <h6 class="fw-bold">Available Courses</h6>
                  <ul class="list-unstyled m-0 p-0">
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Active Shooter</h6></div></li>
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Diversity in the Workplace</h6></div></li>
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Slips, Trips, and Falls</h6></div></li>
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Discipline Without Punishment</h6></div></li>
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Telephone Etiquette</h6></div></li>
                  </ul>
                  <div class="price-action-area text-center mt-3">
                    <router-link :to="{ path: '/company_register', query: { plan: 'hot', type: 'yearly', freeTrial: true } }">
                      <button class="btn btn-outline-primary d-block w-100 py-2 rounded-3 fw-bold">Start Free Trial</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <!-- Sizzling Plan -->
            <div class="col-lg-6 col-xl-4 mb-4 mb-xl-0">
              <div class="popular-outer rounded-4">
                <span class="popular-text text-uppercase text-center text-white fw-medium d-block">Popular</span>
                <div class="price-block p-4 custom-border rounded-4 bg-white">
                  <div v-if="!activeTrial || activeTrial !== 'sizzling-yearly'" class="price-block-inner hot-plane">
                    <div class="d-flex align-items-center mb-3">
                      <img src="img/price/sizzle-plan.svg" alt="" />
                      <h4 class="mb-0 px-3 fw-bold">Sizzling Plan</h4>
                      <el-tooltip popper-class="plan-tool-tip" placement="right" effect="light">
                      <div slot="content">
                        <div class="trial-tool-tip p-3">
                          <p class="fw-bold">The 14-day free trial includes the five courses below.  You can add as many users as you’d like.</p>
                          <p>After 14 days (or by clicking Get Started on your Dashboard), your account will become active and all courses you signed up for will be assigned to your account. </p>
                          <p>You can click to end the free trial or send us an email to cancel with no obligation.</p>
                          <h6>Available Courses</h6>
                          <ul class="list-unstyled m-0 p-0">
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Active Shooter</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Diversity in the Workplace</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Slips, Trips, and Falls</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Discipline Without Punishment</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Telephone Etiquette</h6></div></li>
                          </ul>
                        </div>
                      </div>
                      <el-button><span class="ps-2"><img src="img/price/info.svg" alt="" /></span></el-button>
                    </el-tooltip>
                    </div>
                    <p class="mb-1">Includes the required Food and Alcohol compliance courses, Sexual Harassment, 26 course safety program.</p>
                    <strong class="price fs-1 fw-bold mb-1 lh-sm">$21.49 - 26.89</strong>
                    <span class="price-text fs-6 d-block mb-4">Per user, per year.</span>
                    <div class="price-action-area text-center">
                      <router-link :to="{ path: '/company_register', query: { plan: 'sizzling', type: 'yearly' } }">
                        <button class="btn btn-primary d-block w-100 py-2 mb-3 rounded-3 fw-bold">Get Started</button>
                      </router-link>
                      <button class="btn btn-outline-primary d-block w-100 py-2 mb-3 rounded-3 fw-bold" @click="showTrial('sizzling-yearly')">Start Free 14 Day Trial</button>
                      <a style="cursor: pointer;" @click="dialogVisible = true" class="text-underline fw-bold text-dark">Schedule a Demo</a>
                    </div>
                  </div>
                  <div v-else class="price-block-inner hot-plane replace-box position-relative">
                    <span @click="hideTrial()" class="close-btn d-flex justify-content-end position-absolute">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 14L8 8L14 14M14 2L7.99886 8L2 2" stroke="#A39D9D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </span>
                    <p class="fw-bold mb-3 height-auto">The 14-day free trial includes the five courses below. You can add as many users as you’d like.</p>
                    <p class="mb-3">After 14 days (or by clicking Get Started on your Dashboard), your account will become active and all courses you signed up for will be assigned to your account.</p>
                    <p class="mb-3">You can click to end the free trial or send us an email to cancel with no obligation.</p>
                    <h6 class="fw-bold">Available Courses</h6>
                    <ul class="list-unstyled m-0 p-0">
                      <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Active Shooter</h6></div></li>
                      <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Diversity in the Workplace</h6></div></li>
                      <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Slips, Trips, and Falls</h6></div></li>
                      <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Discipline Without Punishment</h6></div></li>
                      <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Telephone Etiquette</h6></div></li>
                    </ul>
                    <div class="price-action-area text-center mt-3">
                      <router-link :to="{ path: '/company_register', query: { plan: 'sizzling', type: 'yearly', freeTrial: true } }">
                        <button class="btn btn-outline-primary d-block w-100 py-2 rounded-3 fw-bold">Start Free Trial</button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Scorching Plan -->
            <div class="col-lg-6 col-xl-4">
              <div class="price-block border p-4 rounded-4 bg-white">
                <div v-if="!activeTrial || activeTrial !== 'scorching-yearly'" class="price-block-inner hot-plane">
                  <div class="d-flex align-items-center mb-3">
                    <img src="img/price/scorching.svg" alt="" />
                    <h4 class="mb-0 px-3 fw-bold">Scorching Plan</h4>
                    <el-tooltip popper-class="plan-tool-tip" placement="right" effect="light">
                      <div slot="content">
                        <div class="trial-tool-tip p-3">
                          <p class="fw-bold">The 14-day free trial includes the five courses below.  You can add as many users as you’d like.</p>
                          <p>After 14 days (or by clicking Get Started on your Dashboard), your account will become active and all courses you signed up for will be assigned to your account. </p>
                          <p>You can click to end the free trial or send us an email to cancel with no obligation.</p>
                          <h6>Available Courses</h6>
                          <ul class="list-unstyled m-0 p-0">
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Active Shooter</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Diversity in the Workplace</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Slips, Trips, and Falls</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Discipline Without Punishment</h6></div></li>
                            <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Telephone Etiquette</h6></div></li>
                          </ul>
                        </div>
                      </div>
                      <el-button><span class="ps-2"><img src="img/price/info.svg" alt="" /></span></el-button>
                    </el-tooltip>
                  </div>
                  <p class="mb-1">Includes Food and Alcohol compliance courses, Sexual Harassment, and our entire library.</p>
                  <strong class="price fs-1 fw-bold mb-1 lh-sm">$26.89 - 32.29</strong>
                  <span class="price-text fs-6 d-block mb-4">Per user, per year.</span>
                  <div class="price-action-area text-center">
                    <router-link :to="{ path: '/company_register_nd', query: { plan: 'scorching', type: 'yearly' } }">
                      <button class="btn btn-primary d-block w-100 py-2 mb-3 rounded-3 fw-bold">Get Started</button>
                    </router-link>
                    <button class="btn btn-outline-primary d-block w-100 py-2 mb-3 rounded-3 fw-bold" @click="showTrial('scorching-yearly')">Start Free 14 Day Trial</button>
                    <a style="cursor: pointer;" @click="dialogVisible = true" class="text-underline fw-bold text-dark">Schedule a Demo</a>
                  </div>
                </div>
                <div v-else class="price-block-inner hot-plane replace-box position-relative">
                  <span @click="hideTrial()" class="close-btn d-flex justify-content-end position-absolute">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 14L8 8L14 14M14 2L7.99886 8L2 2" stroke="#A39D9D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </span>
                  <p class="fw-bold mb-3 height-auto">The 14-day free trial includes the five courses below. You can add as many users as you’d like.</p>
                  <p class="mb-3">After 14 days (or by clicking Get Started on your Dashboard), your account will become active and all courses you signed up for will be assigned to your account.</p>
                  <p class="mb-3">You can click to end the free trial or send us an email to cancel with no obligation.</p>
                  <h6 class="fw-bold">Available Courses</h6>
                  <ul class="list-unstyled m-0 p-0">
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Active Shooter</h6></div></li>
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Diversity in the Workplace</h6></div></li>
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Slips, Trips, and Falls</h6></div></li>
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Discipline Without Punishment</h6></div></li>
                    <li class="d-flex mb-1 align-items-center"><span><img width="16" src="img/price/orange-tick.svg" alt="" /></span><div class="ac-list-content ps-2"><h6 class="mb-0 fw-normal">Telephone Etiquette</h6></div></li>
                  </ul>
                  <div class="price-action-area text-center mt-3">
                    <router-link :to="{ path: '/company_register', query: { plan: 'scorching', type: 'yearly', freeTrial: true } }">
                      <button class="btn btn-outline-primary d-block w-100 py-2 rounded-3 fw-bold">Start Free Trial</button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap justify-content-center mt-5">
          <p class="mb-3 w-100 text-center fw-bold"> Don’t need a package?  Click here to select individual courses</p>

          <router-link :to="{ path: '/company_register', query: { plan: 'default'} }"
           class="btn btn-primary px-5 py-3 rounded-4 fw-bold text-white">A La Carte Courses</router-link>
        </div>
      </div>
    </div>
  </div>
</section>
      <section class="pb-4 pb-md-5">
        <div class="container position-relative">
          <div class="feature-block px-4 py-5 border rounded-4">
            <div class="heading-head text-center pb-3 pb-md-4">
              <h2 class="fw-bold">Our Features</h2>
              <p>Just because you pay less, doesn't mean you get less features</p>
            </div>
            <div class="row g-3">
                <div class="col-md-3" v-for="features in featuresList">
                        <div class="border p-3 rounded-3 d-flex align-items-center">
                            <img src="img/price/orange-tick.svg" alt="">
                            <span class="ps-2 text-truncate" :title="features" >{{ features }}</span>

                        </div>
                    </div>

            </div>
          </div>
        </div>
      </section>
      <section class="pb-4 pb-md-5">
        <div class="container">
          <div class="adds-on-wrap px-4 py-5 border rounded-4">
            <div class="heading-head text-center pb-4 pb-md-5">
              <h2 class="fw-bold">Add-ons</h2>
            </div>
            <div class="row g-3">
              <div class="col-xl-6">
                <div class="position-relative">
                  <input class="checkbox-design" type="checkbox">
                  <div class="adds-on-block p-3 border rounded-4 position-relative">
                    <span class="active-tick"><img src="img/price/orange-tick.svg" alt=""></span>
                    <div class="row align-items-center">
                      <span class="img-wrap col-2 col-md-auto">
                        <img src="img/price/food-manager.svg" alt="">
                      </span>
                      <div class="col-6 text-start mb-0 adds-on-content">
                        <h5 class="m-0 fw-bold">Food Manager</h5>
                        <span>Safe food handling, hygiene, and regulatory</span>
                      </div>
                      <div class="action-area col-4 ms-auto">
                        <div class="action-block p-3 rounded-4">
                          <strong class="d-block text-center">$1.49</strong>
                          <span class="d-block text-center">Per month</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="position-relative">
                  <input class="checkbox-design" type="checkbox">
                  <div class="adds-on-block p-3 border rounded-4">
                    <span class="active-tick"><img src="img/price/orange-tick.svg" alt=""></span>
                    <div class="row align-items-center">
                      <span class="img-wrap col-2 col-md-auto">
                        <img src="img/price/sexual_harassment.svg" alt="">
                      </span>
                      <div class="col-6 text-start mb-0 adds-on-content">
                        <h5 class="m-0 fw-bold">Sexual Harassment</h5>
                        <span>Prevention, reporting, and workplace safety.</span>
                      </div>
                      <div class="action-area col-4 ms-auto">
                        <div class="action-block p-3 rounded-4">
                          <strong class="d-block text-center">$1.49</strong>
                          <span class="d-block text-center">Per month</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="position-relative">
                  <input class="checkbox-design" type="checkbox">
                  <div class="adds-on-block p-3 border rounded-4">
                    <span class="active-tick"><img src="img/price/orange-tick.svg" alt=""></span>
                    <div class="row align-items-center">
                      <span class="img-wrap col-2 col-md-auto">
                        <img src="img/price/human_trafficking.svg" alt="">
                      </span>
                      <div class="col-6 text-start mb-0 adds-on-content">
                        <h5 class="m-0 fw-bold">Human Trafficking</h5>
                        <span>Awareness, prevention, and legal response.</span>
                      </div>
                      <div class="action-area col-4 ms-auto">
                        <div class="action-block p-3 rounded-4">
                          <strong class="d-block text-center">$1.49</strong>
                          <span class="d-block text-center">Per month</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="position-relative">
                  <input class="checkbox-design" type="checkbox">
                  <div class="adds-on-block p-3 border rounded-4">
                    <span class="active-tick"><img src="img/price/orange-tick.svg" alt=""></span>
                    <div class="row justify-content-end align-items-center">
                      <span class="img-wrap col-2 col-md-auto">
                        <img src="img/price/upload_your_own.svg" alt="">
                      </span>
                      <div class="col-6 text-start mb-0 adds-on-content">
                        <h5 class="m-0 fw-bold">Upload Your Own</h5>
                        <span>Prevention, reporting, and workplace safety.</span>
                      </div>
                      <div class="action-area col-4 ms-auto">
                        <div class="action-block p-3 rounded-4">
                          <strong class="d-block text-center">$1.49</strong>
                          <span class="d-block text-center">Per month</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="position-relative">
                  <input class="checkbox-design" type="checkbox">
                  <div class="adds-on-block p-3 border rounded-4">
                    <span class="active-tick"><img src="img/price/orange-tick.svg" alt=""></span>
                    <div class="row align-items-center">
                      <span class="img-wrap col-2 col-md-auto">
                        <img src="img/price/sms_support.svg" alt="">
                      </span>
                      <div class="col-6 text-start mb-0 adds-on-content">
                        <h5 class="m-0 fw-bold">SMS Support</h5>
                        <span>Prevention, reporting, and workplace safety.</span>
                      </div>
                      <div class="action-area col-4 ms-auto">
                        <div class="action-block p-3 rounded-4">
                          <strong class="d-block text-center">$1.49</strong>
                          <span class="d-block text-center">Per month</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="position-relative">
                  <input class="checkbox-design" type="checkbox">
                  <div class="adds-on-block p-3 border rounded-4">
                    <span class="active-tick"><img src="img/price/orange-tick.svg" alt=""></span>
                    <div class="row align-items-center">
                      <span class="img-wrap col-2 col-md-auto">
                        <img src="img/price/customer_service_skills.svg" alt="">
                      </span>
                      <div class="col-6 text-start mb-0 adds-on-content">
                        <h5 class="m-0 fw-bold">Customer Service Skills</h5>
                        <span>Safe food handling, hygiene, and regulatory</span>
                      </div>
                      <div class="action-area col-4 ms-auto">
                        <div class="action-block p-3 rounded-4">
                          <strong class="d-block text-center">$1.49</strong>
                          <span class="d-block text-center">Per month</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section class="pb-4 pb-md-5">
    <div class="container pb-5">
        <div class="faq-section px-4 py-5 border rounded-4">
            <div class="heading-head text-center pb-4 pb-md-5">
                <h2 class="fw-bold">Frequently Asked Questions</h2>
            </div>
            <div class="accordian-wrap">
                <div class="accordion" id="accordionPanelsStayOpenExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#ac_item1">
                                Is there a free trial? How does the trial work?
                            </button>
                        </h2>
                        <div id="ac_item1" class="accordion-collapse collapse show">
                            <div class="accordion-body">
                                Yes! We offer a 14-day free trial. You can start your trial by signing up for an account. During
                                the trial, you’ll have access to our trial LMS features, including course assignment, user
                                management, and reporting. This allows you to fully explore the platform before making a
                                commitment!
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ac_item2">
                                What happens when the trial ends? Can I extend my trial?
                            </button>
                        </h2>
                        <div id="ac_item2" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                When your trial ends, you’ll need to choose a paid plan to continue using our LMS. If you need
                                more time to evaluate the platform, contact our support team to request an extension. We
                                assess trial extensions on a case-by-case basis.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ac_item3">
                                Do you automatically upgrade to a higher plan when reaching the limits of the current plan?
                            </button>
                        </h2>
                        <div id="ac_item3" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                No, we do not automatically upgrade your plan. If you reach your plan’s limits (e.g., maximum
                                enrolled users or courses), you’ll receive a notification prompting you to upgrade. You can
                                choose to upgrade manually based on your needs.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ac_item4">
                                What happens if I exceed the limits in my plan?
                            </button>
                        </h2>
                        <div id="ac_item4" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                If you exceed your plan’s limits, you will receive a notification and certain features may
                                become restricted. For example:
                                <ul>
                                    <li>If you exceed the maximum number of enrolled users, new users won’t be able to
                                    enroll until you upgrade.</li>
                                    <li>If you exceed the storage or course limits, you won’t be able to add new content until
                                    space is freed up or your plan is upgraded.</li>
                                </ul>
                                You can monitor your usage in your dashboard and upgrade your plan anytime.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ac_item5">
                                What happens to the plan limits if I add the same user to multiple courses?
                            </button>
                        </h2>
                        <div id="ac_item5" class="accordion-collapse collapse">
                            <div class="accordion-body">
                                Each unique user is counted only once toward your total enrolled users, regardless of how
                                many courses they are assigned to. However, if your plan includes a limit on the number of
                                active courses, each additional course will count toward that total.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

    </div>



    <Footer />

    <el-dialog
  title=""
  :visible.sync="dialogVisible"
  width="50%"
  :before-close="handleClose">
  <form class="general-form">
                  <div class="row">
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">First Name<span class="requireField"> *</span></label>
                      <base-input type="text" name="First Name" placeholder="Enter your first name" >
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">Last Name<span class="requireField"> *</span></label>
                      <base-input type="text" name="Last Name" placeholder="Enter your Last Name" >
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">Email Address<span class="requireField">
                          *</span></label>
                      <base-input type="email" name="Email Address" placeholder="Enter your email address">
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">Phone Number<span class="requireField"> *</span></label>
                        <base-input type="text" name="Phone Number" placeholder="Enter your phone number" >
                        </base-input>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold">Company Name<span class="requireField"> *</span></label>
                      <base-input type="text" name="Company Name" placeholder="Enter your company number">
                      </base-input>
                    </div>
                    <div class="col-md-6">
                      <label class="form-control-label fw-bold d-block">Date and Time</label>
                      <el-date-picker
                    v-model="showDate"
                    type="datetime"
                    class="w-100"
                    placeholder="Please select"
                    :picker-options="pickerOptions"
                    >
                    </el-date-picker>

                    </div>


                  </div>
                  <base-button type="primary" class="mt-4 primary-orange border-0 w-100 rounded-3 fw-bold" style="padding: 10px 20px;">Next</base-button>
                </form>

</el-dialog>



  </div>



</template>
<script>

import Header from '../Common/Header.vue';
import Footer from '../Common/Footer.vue';
import { Dialog , DatePicker , Tooltip } from 'element-ui';

export default {
  name: "Price",
  data() {
    return {
        showFirstBlock: true,
        featuresList : ["White Label/Configurable" , "Custom Course Creation" , "Compliance Training" , "Mobile App" , "Job Specific Training" , "Safety Training" , "Security / HR Training" , "Refresh Old Courses" , "Administration levels" , "Custom Reporting" , "Live Filming" , "Upload Your Own" , "Translation" , "Guidebooks / SCORM" , "Automations" , "API integration" , "Scheduled Check in" , "Onboarding/Survey" , "We’ll Be Your Training Department" , "Predictive Learning Paths" , "AI Course Creation" ],
        activeTrial: null,
        dialogVisible: false,
        pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now(); // Disable past dates
        }
      },
        showDate: '',
    };
  },
  methods: {
    toggleBlock() {
      this.showFirstBlock = !this.showFirstBlock;
    },
    showTrial(plan) {
      this.activeTrial = plan; // Set the clicked plan as active
    },
    hideTrial() {
      this.activeTrial = null; // Reset to show all plans
    },
  },
  components: {
    Header,
    Footer,
    [Dialog.name]: Dialog,
    [DatePicker.name]: DatePicker,
    [Tooltip.name]: Tooltip,
  }
}


</script>
<style scoped>

.close-btn {
  right: -12px;
  top: -13px;
  cursor: pointer;
}
.trial-tool-tip {
  max-width: 370px;
}




</style>

