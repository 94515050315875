<template>
    <div>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet">
        <header>
            <div class="header-top-bar text-white d-none d-md-block">
                <div class="container">
                    <div class="header-top-bar-inner d-flex justify-content-between align-items-center">
                        <div>
                            <a href="mailto:support@homeoftraining.com">
                                <img src="img/price/email.svg" alt="" />
                                <span class="ms-2 fw-medium">support@homeoftraining.com</span>
                            </a>
                        </div>
                        <div>
                            <a href="tel:954.676.7900">
                                <img src="img/price/phone.svg" alt="" />
                                <span class="ms-2 fw-medium">954.676.7900</span>
                            </a>
                        </div>
                        <div class="social-icon">
                            <ul class="list-unstyled d-flex m-0">
                                <li class="ms-2">
                                    <a href="https://www.facebook.com/homeoftraining" target="_blank">
                                        <img class="rounded-2" src="img/price/facebook.svg" alt="">
                                    </a>
                                </li>
                                <li class="ms-2">
                                    <a href="https://www.youtube.com/@homeoftraining" target="_blank">
                                        <img class="rounded-2" src="img/price/youtube.svg" alt="">
                                    </a>
                                </li>
                                <li class="ms-2">
                                    <a href="https://www.instagram.com/homeoftraining/" target="_blank">
                                        <img class="rounded-2" src="img/price/instagrame.svg" alt="">
                                    </a>
                                </li>
                                <li class="ms-2">
                                    <a href="https://www.linkedin.com/company/barwize" target="_blank">
                                        <img class="rounded-2" src="img/price/linkdin.svg" alt="">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="header-nav-area py-4">
                <div class="container">
                    <div class="row">
                        <div class="col-4 col-lg-2 col-xl-3">
                        <div class="logo">
                            <a href="https://homeoftraining.com">
                                <img class="img-fluid" width="150" src="/home_of_training.svg" alt="">
                            </a>
                        </div>
                        </div>
                        <div class="col-8 col-lg-10 col-xl-9 d-flex justify-content-end">
                            <nav class="navbar navbar-expand-lg">
                                    <button class="navbar-toggler border-0 p-0" type="button" data-bs-toggle="offcanvas"
                                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                        aria-expanded="false" aria-label="Toggle navigation">
                                        <span class="navbar-toggler-icon"></span>
                                    </button>
                                    <div class="offcanvas offcanvas-start" id="navbarSupportedContent">
                                        <div class="offcanvas-header">
                                            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                        </div>
                                        <ul class="navbar-nav me-auto mb-2 mb-lg-0 align-items-start px-4 px-lg-0">
                                            <li class="nav-item">
                                                <a class="nav-link active fw-semibold" aria-current="page" href="https://homeoftraining.com/services/">Services</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link fw-semibold" aria-current="page" href="https://homeoftraining.com/course-library/">Course Library</a>
                                            </li>

                                            <li class="nav-item">
                                                <a class="nav-link fw-semibold" aria-current="page" href="https://lms.homeoftraining.com/#/signup">Pricing</a>
                                            </li>

                                            <li class="nav-item">
                                                <a class="nav-link fw-semibold" aria-current="page" href="https://homeoftraining.com/video-demos/">Demo</a>
                                            </li>

                                            <li class="nav-item">
                                                <a class="nav-link fw-semibold" aria-current="page" href="https://homeoftraining.com/faqs/">FAQ</a>
                                            </li>

                                            <li class="nav-item">
                                                <a class="nav-link fw-semibold" aria-current="page" href="https://homeoftraining.com/contact/">Contact</a>
                                            </li>

                                            <li class="nav-item">
                                                <a class="nav-link fw-semibold" aria-current="page" href="https://lms.homeoftraining.com/#/login">Login</a>
                                            </li>
                                            <li class="nav-item mt-4 mt-lg-0">
                                                <a class="create-account_btn btn btn-primary nav-link rounded-3 px-4 text-white fw-medium" aria-current="page" href="https://lms.homeoftraining.com/#/signup">Create Account</a>
                                            </li>
                                            
                                            <!-- <li class="nav-item dropdown">
                                                <a class="nav-link dropdown-toggle" href="#" role="button"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    Dropdown
                                                </a>
                                                <ul class="dropdown-menu">
                                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                                    <li>
                                                        <hr class="dropdown-divider">
                                                    </li>
                                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </li> -->
                                           
                                        </ul>
                                        
                                    </div>
                            </nav>
                        </div>
                       
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>
<script>
export default {
    name: "Header"
}
</script>
<style scoped>
.header-top-bar {
    background: linear-gradient(270deg, #e59139, #ea2a29, #ea6629);
    background-size: 600% 600%;
    font-size: 14px;
}

.header-top-bar-inner {
    height: 44px;
}

.header-top-bar a {
    color: #ffffff;
    text-decoration: none;

}
</style>