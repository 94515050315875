<template>
    <footer>
    <div class="footer-top-area py-4 d-none d-lg-block">
        <div class="container">
            <div class="row">
                <div class="col-4">
                    <img  width="136" class="mb-4" src="/home_of_training.svg" alt="">
                    <p>Our mission is to help businesses stay compliant with industry regulations and ensure the safety of their customers.</p>
                </div>
                <div class="col-2">
                    <h5 class="fw-semibold mb-3">Quick links</h5>
                    <ul class="list-unstyled">
                        <li class="mb-3"><a href="https://homeoftraining.com/complaints-appeals/" target="_blank">Complaints & Appeals</a></li>
                        <li class="mb-3"><a href="https://homeoftraining.com/refund-policy/" target="_blank">Refund Policy</a></li>
                        <li class="mb-3"><a href="https://homeoftraining.com/accessibility/" target="_blank">Accessibility</a></li>
                        <li class="mb-3"><a href="https://homeoftraining.com/non-discrimination/" target="_blank">Non-Discrimination</a></li>
                    </ul>
                </div>
                <div class="col-2">
                    <h5 class="fw-semibold mb-3">Follow us</h5>
                    <ul class="list-unstyled">
                        <li class="mb-3"><a href="https://www.facebook.com/homeoftraining" target="_blank">Facebook</a></li>
                        <li class="mb-3"><a href="https://www.instagram.com/homeoftraining/" target="_blank">Instagram</a></li>
                        <li class="mb-3"><a href="https://www.linkedin.com/company/barwize" target="_blank" >LinkedIn</a></li>
                        <li class="mb-3"><a href="https://www.youtube.com/@homeoftraining" target="_blank">YouTube</a></li>
                    </ul>
                </div>
                <div class="col-4">
                    <h5 class="fw-semibold mb-3">Get in touch</h5>
                    <form class="d-flex" style="margin-bottom:39px" role="search">
                        <input class="form-control me-3" style="max-width: 175px;" type="email" placeholder="Email" aria-label="Email">
                        <button class="create-account_btn btn btn-primary nav-link rounded-3 px-4 text-white fw-medium" type="submit">Submit</button>
                    </form>
                    <ul class="list-unstyled">
                        <li class="mb-2">
                            <a href="mailto:info@homeoftraining.com" target="_blank">
                                <span style="display: inline-block; width:25px">
                                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.9 0H17.1C17.3387 0 17.5676 0.0921874 17.7364 0.256282C17.9052 0.420376 18 0.642936 18 0.875V13.125C18 13.3571 17.9052 13.5796 17.7364 13.7437C17.5676 13.9078 17.3387 14 17.1 14H0.9C0.661305 14 0.432387 13.9078 0.263604 13.7437C0.0948211 13.5796 0 13.3571 0 13.125V0.875C0 0.642936 0.0948211 0.420376 0.263604 0.256282C0.432387 0.0921874 0.661305 0 0.9 0ZM15.3279 1.67475L9.1404 6.48725C9.10053 6.5182 9.05102 6.53506 9 6.53506C8.94898 6.53506 8.89947 6.5182 8.8596 6.48725L2.6721 1.67475L1.8279 2.70025L8.0163 7.51275C8.29554 7.72986 8.64245 7.84814 9 7.84814C9.35755 7.84814 9.70446 7.72986 9.9837 7.51275L16.1721 2.70025L15.3279 1.67475Z" fill="#090909"/>
                                </svg>
                            </span>
                                <span>info@homeoftraining.com</span>
                            </a>
                        </li>
                        <li>
                            <a href="tel:954.676.7900">
                                <span style="display: inline-block;width:25px;">
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3 12C9.91111 12 8.53889 11.6973 7.18333 11.092C5.82778 10.4867 4.59444 9.62822 3.48333 8.51667C2.37222 7.40511 1.514 6.17178 0.908667 4.81667C0.303333 3.46156 0.000444444 2.08933 0 0.7C0 0.5 0.0666666 0.333333 0.2 0.2C0.333333 0.0666666 0.5 0 0.7 0H3.4C3.55556 0 3.69444 0.0528888 3.81667 0.158667C3.93889 0.264444 4.01111 0.389333 4.03333 0.533333L4.46667 2.86667C4.48889 3.04444 4.48333 3.19444 4.45 3.31667C4.41667 3.43889 4.35556 3.54444 4.26667 3.63333L2.65 5.26667C2.87222 5.67778 3.136 6.07489 3.44133 6.458C3.74667 6.84111 4.08289 7.21067 4.45 7.56667C4.79444 7.91111 5.15556 8.23067 5.53333 8.52533C5.91111 8.82 6.31111 9.08933 6.73333 9.33333L8.3 7.76667C8.4 7.66667 8.53067 7.59178 8.692 7.542C8.85333 7.49222 9.01156 7.47822 9.16667 7.5L11.4667 7.96667C11.6222 8.01111 11.75 8.09178 11.85 8.20867C11.95 8.32556 12 8.456 12 8.6V11.3C12 11.5 11.9333 11.6667 11.8 11.8C11.6667 11.9333 11.5 12 11.3 12Z" fill="#090909"/>
                                </svg>
                            </span>
                               <span>954-676-7900</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bot-area bg-dark py-3 text-white">
        <div class="container">
            <div class="footer-bot-inner d-flex flex-column flex-md-row justify-content-between align-items-center">
                <span class="mb-2 mb-md-0">© Home of Training, Inc. All rights reserved.</span>
                <ul class="list-unstyled d-flex m-0">
                    <li class="mr-3">
                        <a href="#" class="text-white">Terms & Conditions</a>
                    </li>
                    <li>
                        <a href="#" class="text-white">Privacy Policy</a>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</footer>
</template>
<script>
export default {
    name:"Footer"
}
</script>